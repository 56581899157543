export class Artwork {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public year: number,
    public dimensions: string,
    public technique: string,
    public labels: Map<string, string>,
    public tags: string[],
    public imageId: string,
    public imagePath: string,
    public order: number) {
  }
}
