<ul class="list-group list-group-flush">
  <li *ngFor="let bio of biography" class="list-group-item">
    <a routerLink="/bio/{{artist.id}}/{{ bio.id }}">
      <span class="mr-1">{{bio.date | date }}</span>
      <span class="mr-1 font-weight-light">(<i>{{bio.location}}</i>)</span>:
      <span class="font-weight-bold">{{bio.description}}</span>
      <span class="text-right float-right">{{bio.type ? bio.type.toLowerCase() : 'other'}}</span>
    </a>
  </li>
</ul>
<a [routerLink]="'/bio/' + artist.id + '/new'" class="btn btn-primary" type="button">Add New</a>
