import {Component, OnInit} from '@angular/core';
import {ArtistService} from '../service/artist.service';
import {Artist, Bio, Contact} from '../model/artist';
import {FormBuilder, Validators} from '@angular/forms';
import {Artwork} from '../model/artwork';
import {Message} from '../model/message';
import {MessageType} from '../model/message-type.enum';
import {MessagesService} from '../service/messages.service';
import {FilesService} from '../service/files.service';
import {Location} from '@angular/common';

const email = 'EMAIL';
const facebook = 'FACEBOOK';
const twitter = 'TWITTER';
const instagram = 'INSTAGRAM';

@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.css']
})
export class ArtistComponent implements OnInit {

  artist: Artist = this.newArtist();
  contacts: Map<string, Contact> = new Map<string, Contact>();
  artistForm = this.fb.group({
    name: ['', Validators.required],
    lead: [''],
    about: [''],
    email: ['', Validators.email],
    facebook: [''],
    twitter: [''],
    instagram: ['']
  });
  fileToUpload: File = null;

  constructor(
    private artistService: ArtistService,
    private filesService: FilesService,
    private fb: FormBuilder,
    private messagesService: MessagesService,
    private location: Location) {
  }

  ngOnInit(): void {
    this.artistService.getArtists().subscribe(gar => {
      if ( gar != null && gar.artists != null && gar.artists.length > 0 ) {
        this.artistService.getArtist(gar.artists[0].id).subscribe(a => this.setArtist(a));
      } else {
        this.setArtist(this.newArtist());
      }
    });
  }

  private newArtist(): Artist {
    return this.artistService.newArtist();
  }

  setArtist(artist: Artist){
    this.artist = artist;
    if (artist.contacts) {
      artist.contacts.forEach(c => this.contacts.set(c.type, c));
    }
    this.artistForm.patchValue({
      name: artist.name,
      lead: artist.lead,
      about: artist.about,
      email: this.getOrInitializeContact(email),
      facebook: this.getOrInitializeContact(facebook),
      twitter: this.getOrInitializeContact(twitter),
      instagram: this.getOrInitializeContact(instagram)
    });
  }

  private getOrInitializeContact(type: string): string {
    if ( !this.contacts.get(type) ) {
      this.contacts.set(type, new Contact(0, type, ''));
    }
    return this.contacts.get(type).value;
  }

  private saveArtist(a: Artist) {
    this.artistService.saveArtist(a)
      .subscribe((saved: Artist) => {
        this.messagesService.add(
          new Message('Artist', `saved artist with id ${saved.id}`, MessageType.Succ), 3000);
        this.goBack();
      });
  }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  onSubmit(){
    console.log(this.artistForm.value);
    this.artist.name = this.artistForm.get('name').value;
    this.artist.lead = this.artistForm.get('lead').value;
    this.artist.about = this.artistForm.get('about').value;
    this.setContact(email, this.artistForm.get('email').value);
    this.setContact(facebook, this.artistForm.get('facebook').value);
    this.setContact(twitter, this.artistForm.get('twitter').value);
    this.setContact(instagram, this.artistForm.get('instagram').value);
    this.artist.contacts = Array.from(this.contacts.values());
    if (this.fileToUpload !== null) {
      this.filesService.postFile(this.fileToUpload)
        .subscribe((res) => {
          console.log('files upload result: #{res}', res);
          this.artist.imagePath = res.url;
          this.artist.imageId = res.key;
          this.saveArtist(this.artist);
        });
    } else {
      this.saveArtist(this.artist);
    }
  }

  private setContact(type: string, value: string) {
    if (!this.contacts.get(type)) {
      this.contacts.set(type, new Contact(0, type, value));
    } else {
      this.contacts.get(type).value = value;
    }
  }

  goBack(){
    this.location.back();
  }
}
