import {ArtworkOrder} from './artwork-order';

export class UpdateArtworksOrderRequest {
  constructor(public orders: ArtworkOrder[]) {
  }
}

export class UpdateFailed {
  constructor(public id: number, public error: string) {
  }

}

export class UpdateArtworksOrderResponse {
  constructor(public updated: number, public failed: UpdateFailed[]) {
  }
}
