<form (ngSubmit)="onSubmit()" [formGroup]="artworkForm">
  <div class="form-group">
    <label for="name">Title</label>
    <input
      class="form-control"
      formControlName="name"
      id="name"
      name="name"
      type="text"
    />
    <div class="invalid-feedback">
      Please specify a title.
    </div>
  </div>
  <div class="form-group">
    <label for="description">Description</label>
    <textarea
      class="form-control"
      formControlName="description"
      id="description"
      name="description"
      rows="3"
    ></textarea>
  </div>
  <div class="form-group">
    <label for="year">Year</label>
    <select class="form-control" formControlName="year" id="year" name="year">
      <option *ngFor="let year of years" [value]="year">{{ year }}</option>
    </select>
  </div>
  <div class="form-group">
    <label for="dimensions">Dimensions</label>
    <input
      class="form-control"
      formControlName="dimensions"
      id="dimensions"
      name="dimensions"
      placeholder="e.g. 100x100 cm"
      type="text"
    />
  </div>
  <div class="form-group">
    <label for="technique">Technique</label>
    <input
      class="form-control"
      formControlName="technique"
      id="technique"
      name="technique"
      placeholder="e.g. oil on canvas"
      type="text"
    />
  </div>
  <div class="form-group">
    <label for="order">Order</label>
    <select
      class="form-control"
      formControlName="order"
      id="order"
      name="order"
    >
      <option *ngFor="let order of orders" [value]="order">{{ order }}</option>
    </select>
    <small class="form-text text-muted" id="orderHelpBlock">
      Artworks with lower order value will display higher in the list for the
      same year.
    </small>
  </div>
  <div class="form-group">
    <label for="tags">Tags</label><br/>
    <div class="input-group row ml-0">
      <span *ngFor="let tag of tags.controls; let i = index">
        <span class="badge badge-primary mr-2 mt-2">
          <span>{{ tag.value }}</span>
          <a (click)="removeTag(i)" class="ml-2" style="cursor: pointer;"
          ><sup><strong>X</strong></sup></a
          >
        </span>
      </span>
      <input
        #tag
        class="form-control form-control-sm col-sm-1"
        name="tag"
        type="text"
      />
      <button
        (click)="addTag(tag.value); tag.value = ''"
        class="btn btn-danger btn-sm ml-3"
        type="button"
      >
        Add
      </button>
    </div>
  </div>
  <div class="form-group">
    <label for="image">Image</label><br>
    <img [src]="artwork.imagePath" class="img-thumbnail" width="200px">
    <input
      (change)="handleFileInput($event.target.files)"
      class="form-control-file"
      id="image"
      type="file"/>
  </div>
  <button [disabled]="!artworkForm.valid" class="btn btn-success" type="submit">
    Save
  </button>
  <button (click)="goBack()" class="btn btn-danger ml-3" type="button">
    Cancel
  </button>
</form>
