import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {UsersReport} from '../model/users-report';
import {PageViewsReport} from '../model/page-views-report';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private http: HttpClient) {
  }

  getUsersReport(): Observable<UsersReport> {
    return this.http.get<UsersReport>(environment.apiUrl + '/reports/users');
  }

  getPageViewsReport(): Observable<PageViewsReport> {
    return this.http.get<PageViewsReport>(environment.apiUrl + '/reports/views');
  }

}
