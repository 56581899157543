import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ImageObject} from '../model/image-object';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpClient) {
  }

  postFile(fileToUpload: File): Observable<ImageObject> {
    const endpoint = environment.apiUrl + '/upload';
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http.post<ImageObject>(endpoint, formData);
  }

}
