import {Component, OnInit, ViewChild} from '@angular/core';
import {ReportsService} from '../service/reports.service';
import {PageViews, PageViewsReport} from '../model/page-views-report';
import {CountryViews, UsersReport} from '../model/users-report';
import {MatSort, Sort} from '@angular/material/sort';
import {MatPaginator, PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(
    private reportsService: ReportsService
  ) {
  }

  ngOnInit(): void {
  }

}
