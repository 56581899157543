import { Component, OnInit } from '@angular/core';
import {Artist, Bio} from '../../model/artist';
import {ArtistService} from '../../service/artist.service';
import {FilesService} from '../../service/files.service';
import {FormBuilder} from '@angular/forms';
import {MessagesService} from '../../service/messages.service';

@Component({
  selector: 'app-bio-list',
  templateUrl: './bio-list.component.html',
  styleUrls: ['./bio-list.component.css']
})
export class BioListComponent implements OnInit {

  artist: Artist = this.artistService.newArtist();
  biography: Bio[] = [];

  constructor(
    private artistService: ArtistService,
    private filesService: FilesService,
    private fb: FormBuilder,
    private messagesService: MessagesService
  ) { }

  ngOnInit(): void {
    this.artistService.getArtists().subscribe(gar => {
      if ( gar != null && gar.artists != null && gar.artists.length > 0 ) {
        this.artistService.getArtist(gar.artists[0].id).subscribe(a => this.setArtist(a));
      } else {
        this.setArtist(this.artistService.newArtist());
      }
    });
  }

  setArtist(artist: Artist){
    this.artist = artist;
    if (artist.biography) {
      this.biography = artist.biography;
    }
  }

}
