import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Artist, Bio} from '../../model/artist';
import {ArtistService} from '../../service/artist.service';
import {FormBuilder, Validators} from '@angular/forms';
import {Message} from '../../model/message';
import {MessageType} from '../../model/message-type.enum';
import {MessagesService} from '../../service/messages.service';
import {DatePipe, Location} from '@angular/common';

@Component({
  selector: 'app-bio-edit',
  templateUrl: './bio-edit.component.html',
  styleUrls: ['./bio-edit.component.css']
})
export class BioEditComponent implements OnInit {

  bio: Bio = null;
  artist: Artist = null;
  biography: Map<number, Bio> = new Map<number, Bio>();
  bioForm = this.fb.group({
    date: ['', Validators.required],
    location: [''],
    description: ['', Validators.required],
    type: ['', Validators.required]
  });
  types = ['exhibition', 'award', 'publication', 'other'];

  constructor(
    private route: ActivatedRoute,
    private artistService: ArtistService,
    private fb: FormBuilder,
    private messagesService: MessagesService,
    private datePipe: DatePipe,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.artistService.getArtist(params.artistId).subscribe(a => {
        if (params.id === 'new') {
          this.setArtistBio(a, 0);
        } else {
          this.setArtistBio(a, params.id);
        }
      });
    });
  }

  private setArtistBio(artist: Artist, bioId: number){
    this.artist = artist;
    this.biography.clear();
    if ( this.artist.biography ){
      this.artist.biography.forEach(b => this.biography.set(b.id, b));
    }
    if ( bioId === 0 ){
      this.bio = this.newBio();
    } else {
      this.bio = this.biography.get(bioId);
    }
    console.log(new Date(this.bio.date).toDateString());
    this.bioForm.patchValue({
      date: this.datePipe.transform(new Date(this.bio.date), 'yyyy-MM-dd'),
      location: this.bio.location,
      description: this.bio.description,
      type: this.bio.type ? this.bio.type.toLowerCase() : 'other'
    });
  }

  private saveArtist(a: Artist) {
    this.artistService.saveArtist(a)
      .subscribe((saved: Artist) => {
        this.messagesService.add(
          new Message('Artist', `saved artist bio with id ${saved.id}`, MessageType.Succ), 3000);
        this.goBack();
      });
  }

  onSubmit(){
    console.log(this.bioForm.value);
    this.bio.date = new Date(Date.parse(this.bioForm.get('date').value));
    this.bio.location = this.bioForm.get('location').value;
    this.bio.description = this.bioForm.get('description').value;
    this.bio.type = this.bioForm.get('type').value.toUpperCase();
    this.biography.set(this.bio.id, this.bio);
    this.artist.biography = Array.from(this.biography.values());
    this.saveArtist(this.artist);
  }

  delete() {
    this.biography.delete(this.bio.id);
    this.artist.biography = Array.from(this.biography.values());
    this.saveArtist(this.artist);
  }

  private newBio(): Bio {
    return new Bio(0, '', new Date(Date.now()), '', 'EXHIBITION');
  }

  goBack(){
    this.location.back();
  }
}
