<div class="row">
  <div class="col-9 col-lg-10">
    <div *ngIf="filtered" class="list-group mb-3">
      <a *ngFor="let artwork of artworks" class="list-group-item list-group-item-action"
         routerLink="/artworks/{{ artwork.id }}">
        <div class="media">
          <img [src]="artwork.imagePath" class="mr-3" width="65px">
          <div class="media-body">
            <h3 class="mt-0">{{ artwork.name }} - {{ artwork.year }}</h3>
            {{ artwork.description }}
          </div>
        </div>
      </a>
    </div>
    <div *ngIf="!filtered">
      <div *ngFor="let yc of years" class="row mt-2">
        <div class="col">
          <h2>{{yc.year}}</h2>
          <div class="row" cdkDropList (cdkDropListDropped)="drop(yc.year, $event)">
            <a *ngFor="let artwork of artworksByYear.get(yc.year)" class="list-group-item list-group-item-action"
               routerLink="/artworks/{{ artwork.id }}" cdkDrag>
              <div class="media">
                <img [src]="artwork.imagePath" class="mr-3" width="65px">
                <div class="media-body">
                  <h3 class="mt-0">{{ artwork.name }} - {{ artwork.year }}</h3>
                  {{ artwork.description }}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3 col-lg-2">
    <a *ngFor='let year of years' [queryParams]="{ year: year.year }"
       type="button" class="btn btn-primary mb-3 btn-block {{selectedYear == year.year ? 'active' : ''}}"
       routerLink="/artworks">
      {{ year.year}} <span class="badge badge-pill badge-light ml-1">{{year.count}}</span>
    </a>
    <div class="mt-5"></div>
    <a *ngFor='let tc of tagsCount' [queryParams]="{ tags: tc.tag }"
       type="button" class="btn btn-primary btn-sm mb-2 ml-2 {{selectedTag == tc.tag ? 'active' : ''}}"
       routerLink="/artworks">
      {{ tc.tag}} <span class="badge badge-pill badge-light ml-1">{{tc.count}}</span>
    </a>
  </div>
</div>
<div class="row mt-4">
  <div class="col-3">
    <a [routerLink]="'/artworks/new/edit'" class="btn btn-primary" type="button">Add New</a>
  </div>
</div>
