import {Injectable} from '@angular/core';
import {Artwork} from '../model/artwork';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {GetArtworksResponse} from '../model/get-artworks-response';
import {ArtworksPerYearCount, YearCount} from '../model/year-count';
import {ArtworksPerTagCount, TagCount} from '../model/tag-count';
import {ArtworkOrder} from '../model/artwork-order';
import {UpdateArtworksOrderRequest, UpdateArtworksOrderResponse} from '../model/update-artworks-order';

@Injectable({
  providedIn: 'root'
})
export class ArtworksService {

  constructor(
    private http: HttpClient) {
  }

  getArtworks(): Observable<GetArtworksResponse> {
    return this.http.get<GetArtworksResponse>(environment.apiUrl + '/artworks', {params: { page: '1', size: '50'} });
  }

  getArtworksForTags(tags: string[]): Observable<GetArtworksResponse> {
    return this.http.get<GetArtworksResponse>(environment.apiUrl + '/artworks', {params: {tags: tags.join(','), page: '1', size: '50'}});
  }

  getArtworksForYear(year: string): Observable<GetArtworksResponse> {
    return this.http.get<GetArtworksResponse>(environment.apiUrl + '/artworks', {params: {year: year, page: '1', size: '50'}});
  }

  getYearsCount(): Observable<YearCount[]> {
    return this.http.get<ArtworksPerYearCount>(environment.apiUrl + '/years').pipe(map(value => value.counts));
  }

  getTagsCount(): Observable<TagCount[]> {
    return this.http.get<ArtworksPerTagCount>(environment.apiUrl + '/tags').pipe(map(value => value.counts));
  }

  // returns true if source includes all in target
  includesAll(source: string[], target: string[]): boolean {
    for (const item of target) {
      if (!source.includes(item)) {
        return false;
      }
    }
    return true;
  }

  getArtwork(id: string): Observable<Artwork> {
    return this.http.get<Artwork>(environment.apiUrl + '/artworks/' + id);
  }

  saveArtwork(artwork: Artwork): Observable<Artwork> {
    const endpoint = environment.apiUrl + '/artworks';
    return this.http.post<Artwork>(endpoint, artwork)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteArtwork(id: number): Observable<any> {
    return this.http.delete(environment.apiUrl + '/artworks/' + id);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

  updateOrders(orders: ArtworkOrder[]): Observable<UpdateArtworksOrderResponse>{
    return this.http.put<UpdateArtworksOrderResponse>(environment.apiUrl + '/artworks/order', new UpdateArtworksOrderRequest(orders));
  }
}
