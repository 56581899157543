import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ArtworkListComponent} from './artworks/artwork-list/artwork-list.component';
import {ArtworkDetailComponent} from './artworks/artwork-detail/artwork-detail.component';
import {HeaderComponent} from './shared/header/header.component';
import {FooterComponent} from './shared/footer/footer.component';
import {MenuComponent} from './shared/menu/menu.component';
import {MessagesComponent} from './shared/messages/messages.component';
import {ArtworksComponent} from './artworks/artworks.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ArtistComponent} from './artist/artist.component';
import {NewsComponent} from './news/news.component';
import {BioComponent} from './bio/bio.component';
import {ArtworkEditComponent} from './artworks/artwork-edit/artwork-edit.component';
import {httpInterceptorProviders} from './http-interceptors';
import { BioListComponent } from './bio/bio-list/bio-list.component';
import { BioEditComponent } from './bio/bio-edit/bio-edit.component';
import {DatePipe} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import { UserViewsComponent } from './dashboard/user-views/user-views.component';
import { PageViewsComponent } from './dashboard/page-views/page-views.component';
import { CountryViewsComponent } from './dashboard/country-views/country-views.component';
import {LineChartModule} from '@swimlane/ngx-charts';

@NgModule({
  declarations: [
    AppComponent,
    ArtworkListComponent,
    ArtworkDetailComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    MessagesComponent,
    ArtworksComponent,
    DashboardComponent,
    ArtistComponent,
    NewsComponent,
    BioComponent,
    ArtworkEditComponent,
    BioListComponent,
    BioEditComponent,
    UserViewsComponent,
    PageViewsComponent,
    CountryViewsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    LineChartModule
  ],
  providers: [
    httpInterceptorProviders,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
