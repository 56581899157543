import { Component, OnInit } from '@angular/core';
import {ReportsService} from '../../service/reports.service';
import {UsersReport} from '../../model/users-report';

@Component({
  selector: 'app-user-views',
  templateUrl: './user-views.component.html',
  styleUrls: ['./user-views.component.css']
})
export class UserViewsComponent implements OnInit {

  constructor(private reportsService: ReportsService) { }

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Date';
  yAxisLabel: string = 'Visits';
  timeline: boolean = true;

  data: object[];

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };


  ngOnInit(): void {
    this.reportsService.getUsersReport().subscribe(res => {
      this.setData(res);
    });
  }

  setData(users: UsersReport){
    const series = users.dates.map<object>(value => {
      return {name: value.date, value: value.views};
    });
    this.data = [{name: 'Visits', series}];
  }

}
