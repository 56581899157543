<form (ngSubmit)="onSubmit()" [formGroup]="bioForm">
  <div class="form-group">
    <label for="date">Date</label>
    <input
      class="form-control"
      formControlName="date"
      id="date"
      name="date"
      type="date"
    />
    <div class="invalid-feedback">
      Please specify a date.
    </div>
  </div>
  <div class="form-group">
    <label for="location">Location</label>
    <input
      class="form-control"
      formControlName="location"
      id="location"
      name="location"
      type="text"
      placeholder="Location"
    />
  </div>
  <div class="form-group">
    <label for="description">Description</label>
    <textarea
      class="form-control"
      formControlName="description"
      id="description"
      name="description"
      rows="3"
      placeholder="Details about entry"
    ></textarea>
  </div>
  <div class="form-group">
    <label for="type">Type</label>
    <select
      class="form-control"
      formControlName="type"
      id="type"
      name="type"
    >
      <option *ngFor="let type of types" [value]="type">{{ type }}</option>
    </select>
  </div>
  <button [disabled]="!bioForm.valid" class="btn btn-success" type="submit">
    Save
  </button>
  <button (click)="delete()" class="btn btn-danger ml-3" type="button">
    Delete
  </button>
  <button (click)="goBack()" class="btn btn-primary ml-3" type="button">
    Cancel
  </button>
</form>
