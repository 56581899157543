<div class="mat-elevation-z8">
  <table *ngIf="sortedCountryViews" mat-table [dataSource]="pagedCountryViews" matSort
         (matSortChange)="sortCountryViews($event)" class="mat-elevation-z8">
    <ng-container matColumnDef="country">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Country</mat-header-cell>
      <mat-cell *matCellDef="let element"><a href=""> {{element.country}} </a></mat-cell>
    </ng-container>
    <ng-container matColumnDef="views">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Views</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.views}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="cColumnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let row; columns: cColumnsToDisplay;"></mat-row>
  </table>
  <mat-paginator *ngIf="users" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                 [length]="users.countries.length" [pageSize]="countriesPageSize" (page)="pageContriesEvent($event)"></mat-paginator>
</div>

