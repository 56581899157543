export class Bio {
  constructor(
    public id: number,
    public description: string,
    public date: Date,
    public location: string,
    public type: string
  ) {
  }
}

export class Contact {
  constructor(
    public id: number,
    public type: string,
    public value: string
  ) {
  }
}

export class Artist {
  constructor(
    public id: number,
    public name: string,
    public lead: string,
    public about: string,
    public imagePath: string,
    public imageId: string,
    public biography: Bio[],
    public contacts: Contact[]
  ) {
  }
}
