import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {Artist} from '../model/artist';
import {GetArtistsResponse} from '../model/get-artists-response';

@Injectable({
  providedIn: 'root'
})
export class ArtistService {

  constructor(
    private http: HttpClient) {
  }

  saveArtist(artist: Artist): Observable<Artist> {
    const endpoint = environment.apiUrl + '/artists';
    return this.http.post<Artist>(endpoint, artist)
      .pipe(
        catchError(this.handleError)
      );
  }

  getArtists(): Observable<GetArtistsResponse> {
    return this.http.get<GetArtistsResponse>(environment.apiUrl + '/artists', {params: { page: '1', size: '1'} });
  }

  getArtist(id: number): Observable<Artist> {
    return this.http.get<Artist>( environment.apiUrl + '/artists/' + id);
  }

  newArtist(): Artist {
    return new Artist(0, '', '', '', '', '', [], []);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
