import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ArtworksComponent} from './artworks/artworks.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ArtistComponent} from './artist/artist.component';
import {NewsComponent} from './news/news.component';
import {BioComponent} from './bio/bio.component';
import {ArtworkDetailComponent} from './artworks/artwork-detail/artwork-detail.component';
import {ArtworkListComponent} from './artworks/artwork-list/artwork-list.component';
import {ArtworkEditComponent} from './artworks/artwork-edit/artwork-edit.component';
import {BioListComponent} from './bio/bio-list/bio-list.component';
import {BioEditComponent} from './bio/bio-edit/bio-edit.component';


const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/dashboard'},
  {
    path: 'artworks', component: ArtworksComponent, children: [
      {path: '', component: ArtworkListComponent},
      {path: 'tags/:tag', component: ArtworkListComponent},
      {path: ':id', component: ArtworkDetailComponent},
      {path: ':id/edit', component: ArtworkEditComponent}
    ]
  },

  {path: 'dashboard', component: DashboardComponent},
  {path: 'artist', component: ArtistComponent},
  {
    path: 'bio', component: BioComponent, children: [
      {path: '', component: BioListComponent},
      {path: ':artistId/:id', component: BioEditComponent}
    ]
  },
  {path: 'news', component: NewsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
