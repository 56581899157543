<div class="mat-elevation-z8">
  <table *ngIf="pagedViews" mat-table [dataSource]="pagedViews" matSort (matSortChange)="sortPageViews($event)"
         class="mat-elevation-z8">
    <ng-container matColumnDef="pagePath">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Path</mat-header-cell>
      <mat-cell *matCellDef="let element"><a href=""> {{element.pagePath}} </a></mat-cell>
    </ng-container>
    <ng-container matColumnDef="views">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Views</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.views}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="pvColumnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let row; columns: pvColumnsToDisplay;"></mat-row>
  </table>
  <mat-paginator *ngIf="pageViews" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                 [length]="pageViews.views.length" [pageSize]="pageSize" (page)="pageEvent($event)"></mat-paginator>
</div>

