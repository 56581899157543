import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import {PageViews, PageViewsReport} from '../../model/page-views-report';
import {ReportsService} from '../../service/reports.service';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-page-views',
  templateUrl: './page-views.component.html',
  styleUrls: ['./page-views.component.css']
})
export class PageViewsComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  pageViews: PageViewsReport;
  sortedViews: PageViews[];
  pagedViews: PageViews[];
  pageIndex = 0;
  pageSize = 10;

  pvColumnsToDisplay = ['pagePath', 'views'];

  constructor(
    private reportsService: ReportsService
  ) {
  }

  ngOnInit(): void {
    this.reportsService.getPageViewsReport().subscribe(res => {
      this.pageViews = res;
      this.sortedViews = res.views;
      const startIndex = this.pageSize * this.pageIndex;
      this.pagedViews = this.sortedViews.slice(startIndex, startIndex + this.pageSize);
    });
  }

  sortPageViews(sort: Sort) {
    this.sortedViews = this.pageViews.views.slice().sort((a,b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'views': return this.compare(a.views, b.views, isAsc);
        case 'pagePath': return this.compare(a.pagePath, b.pagePath, isAsc);
        default: return 0;
      }
    });
    const startIndex = this.pageSize * this.pageIndex;
    this.pagedViews = this.sortedViews.slice(startIndex, startIndex + this.pageSize);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  pageEvent(pageEvent: PageEvent) {
    this.pageIndex = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    const startIndex = this.pageSize * this.pageIndex;
    this.pagedViews = this.sortedViews.slice(startIndex, startIndex + this.pageSize);
  }

}
