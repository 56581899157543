import {Injectable} from '@angular/core';
import {Message} from '../model/message';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  messages: Message[] = [];

  constructor() {
  }

  add(message: Message, timeout = 0) {
    length = this.messages.push(message);
    if (timeout > 0) {
      setTimeout(() => this.remove(length - 1), timeout);
    }
  }

  remove(index: number) {
    delete this.messages[index];
  }

  clear() {
    this.messages = [];
  }
}
