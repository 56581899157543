<nav class="col-md-2 d-none d-md-block bg-light sidebar">
  <div class="sidebar-sticky">
    <ul class="nav flex-column">
      <li class="nav-item">
        <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">
          <span data-feather="home"></span>
          Dashboard <span class="sr-only">(current)</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/artworks" routerLinkActive="active">
          <span data-feather="file"></span>
          Artworks
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/artist" routerLinkActive="active">
          <span data-feather="shopping-cart"></span>
          Artist
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/bio" routerLinkActive="active">
          <span data-feather="users"></span>
          Bio
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/news" routerLinkActive="active">
          <span data-feather="bar-chart-2"></span>
          News
        </a>
      </li>
    </ul>
  </div>
</nav>
