import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import {CountryViews, UsersReport} from '../../model/users-report';
import {ReportsService} from '../../service/reports.service';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-country-views',
  templateUrl: './country-views.component.html',
  styleUrls: ['./country-views.component.css']
})
export class CountryViewsComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  users: UsersReport;
  sortedCountryViews: CountryViews[];
  pagedCountryViews: CountryViews[];
  countriesPageIndex = 0;
  countriesPageSize = 10;

  cColumnsToDisplay = ['country', 'views'];

  constructor(
    private reportsService: ReportsService
  ) {
  }

  ngOnInit(): void {
    this.reportsService.getUsersReport().subscribe(res => {
      this.users = res;
      this.sortedCountryViews = res.countries;
      const startIndex = this.countriesPageSize * this.countriesPageIndex;
      this.pagedCountryViews = this.sortedCountryViews.slice(startIndex, startIndex + this.countriesPageSize);
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortCountryViews(sort: Sort) {
    this.sortedCountryViews = this.users.countries.slice().sort((a,b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'views': return this.compare(a.views, b.views, isAsc);
        case 'country': return this.compare(a.country, b.country, isAsc);
        default: return 0;
      }
    });
    const startIndex = this.countriesPageSize * this.countriesPageIndex;
    this.pagedCountryViews = this.sortedCountryViews.slice(startIndex, startIndex + this.countriesPageSize);
  }

  pageContriesEvent(pageEvent: PageEvent) {
    this.countriesPageIndex = pageEvent.pageIndex;
    this.countriesPageSize = pageEvent.pageSize;
    const startIndex = this.countriesPageSize * this.countriesPageIndex;
    this.pagedCountryViews = this.sortedCountryViews.slice(startIndex, startIndex + this.countriesPageSize);
  }
}
