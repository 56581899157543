import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ArtworksService} from 'src/app/service/artworks.service';
import {Artwork} from 'src/app/model/artwork';
import {Location} from '@angular/common';
import {Message} from '../../model/message';
import {MessageType} from '../../model/message-type.enum';
import {MessagesService} from '../../service/messages.service';

@Component({
  selector: 'app-artwork-detail',
  templateUrl: './artwork-detail.component.html',
  styleUrls: ['./artwork-detail.component.css']
})
export class ArtworkDetailComponent implements OnInit {

  artwork: Artwork;

  constructor(
    private route: ActivatedRoute,
    private artworksService: ArtworksService,
    private location: Location,
    private messagesService: MessagesService
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => this.getArtwork(params.id));
  }

  getArtwork(id: string): void {
    this.artworksService.getArtwork(id)
      .subscribe(artwork => this.artwork = artwork);
  }

  goBack(): void {
    this.location.back();
  }

  delete(id: number) {
    this.artworksService.deleteArtwork(id).subscribe(() => {
      this.messagesService.add(
        new Message('Artworks', `deleted artwork with id ${id}`, MessageType.Succ), 3000);
      this.goBack();
    });
  }
}
