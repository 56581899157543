import {Component, OnInit} from '@angular/core';
import {MessagesService} from 'src/app/service/messages.service';
import {Message} from 'src/app/model/message';
import {MessageType} from 'src/app/model/message-type.enum';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  constructor(public messagesService: MessagesService) {
  }

  ngOnInit(): void {
  }

  getClass(message: Message): string {
    if (message.type == null) {
      return 'alert-primary';
    }
    switch (message.type) {
      case MessageType.Error:
        return 'alert-danger';
      case MessageType.Warn:
        return 'alert-warning';
      case MessageType.Info:
        return 'alert-info';
      case MessageType.Succ:
        return 'alert-success';

      default:
        return 'alert-primary';
    }
  }

}
