import {Component, OnInit} from '@angular/core';
import {ArtworksService} from 'src/app/service/artworks.service';
import {Artwork} from 'src/app/model/artwork';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {YearCount} from '../../model/year-count';
import {TagCount} from '../../model/tag-count';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ArtworkOrder} from '../../model/artwork-order';
import {Message} from '../../model/message';
import {MessageType} from '../../model/message-type.enum';
import {MessagesService} from '../../service/messages.service';

@Component({
  selector: 'app-artwork-list',
  templateUrl: './artwork-list.component.html',
  styleUrls: ['./artwork-list.component.css']
})
export class ArtworkListComponent implements OnInit {

  tags: string[];
  artworks: Artwork[] = [];
  years: YearCount[] = [];
  tagsCount: TagCount[] = [];
  selectedYear: string;
  selectedTag: string;
  artworksByYear: Map<number, Artwork[]> = new Map<number, Artwork[]>();
  filtered = false;

  constructor(
    private artworksService: ArtworksService,
    private route: ActivatedRoute,
    private messagesService: MessagesService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.artworksService.getYearsCount().subscribe( res => this.years = res );
      this.artworksService.getTagsCount().subscribe(res => this.tagsCount = res);
      const tags: string = params.tags;
      const year: string = params.year;
      this.selectedYear = '';
      this.selectedTag = '';
      if (tags) {
        this.filtered = true;
        this.tags = tags.split(',');
        this.artworksService.getArtworksForTags(this.tags)
          .subscribe(res => this.artworks = res.artworks);
        this.selectedTag = this.tags[0];
      } else if (year){
        this.filtered = true;
        this.selectedYear = year;
        this.artworksService.getArtworksForYear(year).subscribe(res => this.artworks = res.artworks);
      } else {
        this.filtered = false;
        if ( this.years && this.years.length > 0 ){
          this.getArtworksByYear(this.years);
        } else {
          this.artworksService.getYearsCount().subscribe( yc => {
            this.years = yc;
            this.getArtworksByYear(yc);
          });
        }
      }
    });
  }

  drop(year: number, event: CdkDragDrop<string[]>) {
    const list = this.artworksByYear.get(year);
    moveItemInArray(list, event.previousIndex, event.currentIndex);
    const updated: ArtworkOrder[] = [];
    list.forEach((artwork, index) => {
      if ( artwork.order !== index ){
        updated.push(new ArtworkOrder(artwork.id, index));
        artwork.order = index;
      }
    });
    this.artworksByYear.set(year, list);
    console.log(updated);
    if ( updated.length > 0 ){
      this.artworksService.updateOrders(updated).subscribe(res => {
        if ( res.updated > 0 ) {
          this.messagesService.add(
            new Message('Artworks', `order updated for ${res.updated} artworks`, MessageType.Succ), 3000);
        }
        if ( res.failed && res.failed.length > 0) {
          res.failed.forEach(f => {
            this.messagesService.add(
              new Message('Artworks', `order update failed for artwork ${f.id} : ${f.error}`, MessageType.Warn), 3000);
          });
        }
      });
    }
  }

  private getArtworksByYear(years: YearCount[]) {
    years.forEach(yc => {
      this.artworksService.getArtworksForYear(`${yc.year}`).subscribe(res => {
        this.artworksByYear.set(yc.year, res.artworks);
      });
    });
  }
}
