import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ArtworksService} from 'src/app/service/artworks.service';
import {Location} from '@angular/common';
import {Artwork} from 'src/app/model/artwork';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {FilesService} from 'src/app/service/files.service';
import {Message} from '../../model/message';
import {MessageType} from '../../model/message-type.enum';
import {MessagesService} from '../../service/messages.service';

@Component({
  selector: 'app-artwork-edit',
  templateUrl: './artwork-edit.component.html',
  styleUrls: ['./artwork-edit.component.css']
})
export class ArtworkEditComponent implements OnInit {

  artwork: Artwork = this.newArtwork();
  artworkForm = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    year: ['', Validators.required],
    dimensions: [''],
    technique: [''],
    tags: this.fb.array([]),
    order: ['']
  });
  fileToUpload: File = null;
  years: number[] = this.getYears();
  orders: number[] = [...Array(100).keys()];

  constructor(
    private route: ActivatedRoute,
    private artworksService: ArtworksService,
    private filesService: FilesService,
    private location: Location,
    private fb: FormBuilder,
    private messagesService: MessagesService
  ) {
  }

  get tags(): FormArray {
    return this.artworkForm.get('tags') as FormArray;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.id === 'new') {
        this.setArtwork(this.newArtwork());
      } else {
        this.getArtwork(params.id);
      }
    });
  }

  getYears(startYear = 1980): number[] {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  }

  getArtwork(id: string): void {
    this.artworksService.getArtwork(id)
      .subscribe(a => this.setArtwork(a));
  }

  setArtwork(artwork: Artwork) {
    this.artwork = artwork;
    this.artworkForm.patchValue({
      name: artwork.name,
      description: artwork.description,
      dimensions: artwork.dimensions,
      technique: artwork.technique,
      year: artwork.year,
      order: artwork.order,
    });
    if (artwork.tags != null) {
      artwork.tags.forEach(tag => this.addTag(tag));
    }

    console.log(this.artworkForm.value);
  }

  addTag(tag = '') {
    this.tags.push(this.fb.control(tag));
  }

  removeTag(index: number) {
    this.tags.removeAt(index);
  }

  goBack(): void {
    this.location.back();
  }

  onSubmit(): void {
    console.log(this.artworkForm.value);
    this.artwork.name = this.artworkForm.get('name').value;
    this.artwork.description = this.artworkForm.get('description').value;
    this.artwork.dimensions = this.artworkForm.get('dimensions').value;
    this.artwork.technique = this.artworkForm.get('technique').value;
    this.artwork.year = +this.artworkForm.get('year').value;
    this.artwork.order = +this.artworkForm.get('order').value;
    this.artwork.tags = this.tags.getRawValue();
    if (this.fileToUpload !== null) {
      this.filesService.postFile(this.fileToUpload)
        .subscribe((res) => {
          console.log('files upload result: #{res}', res);
          this.artwork.imagePath = res.url;
          this.artwork.imageId = res.key;
          this.saveArtwork(this.artwork);
        });
    } else {
      this.saveArtwork(this.artwork);
    }
  }

  private saveArtwork(a: Artwork) {
    this.artworksService.saveArtwork(a)
      .subscribe((saved: Artwork) => {
        this.messagesService.add(
          new Message('Artworks', `saved artwork with id ${saved.id}`, MessageType.Succ), 3000);
        this.goBack();
      });
  }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  private newArtwork() {
    return new Artwork(0, '', '', (new Date()).getFullYear(), '', '',
      new Map<string, string>(), [], '', '', 1);
  }

}
