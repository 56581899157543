<app-header></app-header>
<div class="container-fluid">
  <div class="row">
    <app-menu></app-menu>

    <main class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4" role="main">
      <app-messages></app-messages>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
<app-footer></app-footer>
