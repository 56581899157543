<h1>Dashboard</h1>

<div class="row">
  <app-user-views></app-user-views>
</div>
<div class="row">
  <div class="col-9 col-lg-5">
    <app-page-views></app-page-views>
  </div>
  <div class="col-9 col-lg-5">
    <app-country-views></app-country-views>
  </div>
</div>
