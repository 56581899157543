<div *ngIf="artwork" class="media">
  <img [src]="artwork.imagePath" alt="Generic placeholder image" class="mr-3 w-50">
  <div class="media-body">
    <h3 class="mt-0">{{ artwork.name }} ({{ artwork.year }})</h3>
    <p class="lead">{{ artwork.description }}</p>
    <p><strong>{{ artwork.technique }}</strong></p>
    <p><em>{{ artwork.dimensions }}</em></p>
    <a *ngFor='let label of artwork.tags'
       [queryParams]="{ tags: label }"
       class="badge badge-primary mr-1"
       routerLink="/artworks">{{ label }}</a>
  </div>
</div>
<br>
<a *ngIf="artwork" [routerLink]="'/artworks/' + artwork.id + '/edit'" class="btn btn-primary" type="button">Edit</a>
<button (click)="goBack()" class="btn btn-primary ml-3" type="button">Back</button>
<button (click)="delete(artwork.id)" class="btn btn-danger ml-3" type="button">Delete</button>
