<form (ngSubmit)="onSubmit()" [formGroup]="artistForm">
  <div class="form-group">
    <label for="name">Name</label>
    <input
      class="form-control"
      formControlName="name"
      id="name"
      name="name"
      type="text"
      placeholder="Your name here"
    />
    <div class="invalid-feedback">
      Please specify a name.
    </div>
  </div>
  <div class="form-group">
    <label for="name">Lead</label>
    <input
      class="form-control"
      formControlName="lead"
      id="lead"
      name="lead"
      type="text"
      placeholder="Catchy one phrase title about yourself"
    />
  </div>
  <div class="form-group">
    <label for="about">About</label>
    <textarea
      class="form-control"
      formControlName="about"
      id="about"
      name="about"
      rows="8"
      placeholder="Details about yourself, interest, type of work etc"
    ></textarea>
  </div>
  <div class="form-group">
    <label for="image">Profile Image</label><br>
    <img [src]="artist.imagePath" class="img-thumbnail" width="200px">
    <input
      (change)="handleFileInput($event.target.files)"
      class="form-control-file"
      id="image"
      type="file"/>
  </div>
  <div class="form-group">
    <label for="email">Email</label>
    <input
      class="form-control"
      formControlName="email"
      id="email"
      name="email"
      type="email"
      placeholder="name@example.com"
    />
    <div class="invalid-feedback">
      Please provide a valid Email address.
    </div>
  </div>
  <div class="form-group">
    <label for="facebook">Facebook</label>
    <input
      class="form-control"
      formControlName="facebook"
      id="facebook"
      name="facebook"
      type="text"
      placeholder="Your facebook id"
    />
  </div>
  <div class="form-group">
    <label for="Twitter">Twitter</label>
    <input
      class="form-control"
      formControlName="twitter"
      id="twitter"
      name="twitter"
      type="text"
      placeholder="Your twitter id"
    />
  </div>
  <div class="form-group">
    <label for="instagram">Instagram</label>
    <input
      class="form-control"
      formControlName="instagram"
      id="instagram"
      name="instagram"
      type="text"
      placeholder="Your instagram id"
    />
  </div>
  <button [disabled]="!artistForm.valid" class="btn btn-success" type="submit">
    Save
  </button>
  <button (click)="goBack()" class="btn btn-danger ml-3" type="button">
    Cancel
  </button>
</form>
